<template>
  <div class="plans-page">
    <v-container fluid class="py-6" v-if="scopes.length">
      <v-card class="plans-card" elevation="3" rounded="lg">
        <v-card-title class="text-h5 font-weight-medium">
          <v-icon left color="primary">mdi-package-variant-closed</v-icon>
          Subscription Plans
        </v-card-title>
        <v-card-text>
          <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th 
            v-for="(header, index) in headers" :key="`header-${index}`"
            :class="`text-${header.align}`"
            >
            {{ header.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in scopes"
          :key="index"
        >
          <td 
            v-for="(header, i) in headers" 
            :key="`item-${i}`"
            :class="`text-${header.align}`"
            >
            <div 
              v-if="!!i && item.key === 'logistics_connect' && !!item[header.value]"
              style="display: flex; align-items:center; justify-content: center;"
              >
              <v-select
                dense
                hide-details
                style="max-width: 5em"
                outlined
                v-model="amount_logistics_connects"
                :items="[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25]"
                >
              </v-select>
            </div>
            <v-icon
              v-else-if="typeof item[header.value] === 'boolean'"
              :color="!item[header.value] ? 'red' : 'green'"
              >
              {{ !item[header.value] ? 'mdi-close-thick' : 'mdi-check-bold'}}
            </v-icon>
            <span v-else-if="item.key === 'monthly_price'">
              {{ header.id !== 5 && item[header.value] || logistics_price | SubscriptionPriceFilter }}
            </span>
            <span v-else>
              {{ item[header.value] === Infinity ? 'Unlimited' : item[header.value]  }}
            </span>
            <ToolTip
              v-if="!i && item.tooltip">
             {{ item.tooltip }}
            </ToolTip>
          </td>
        </tr>
        </tbody>
        <tfoot v-if="user && get_plan">
        <tr>
          <td 
            v-for="(header, i) in headers" 
            :key="`item-${i}`"
            :class="`text-${header.align}`"
            >
            <v-btn
              v-if="i > 0 && header.id === 5 && get_plan.id === 5 && get_plan.amount_logistics_connects !== amount_logistics_connects"
              rounded
              color="success"
              @click="select_plan(header.id)"
              :loading="changing_plan === (header.id)"
              :disabled="changing_plan !== false"
              >
              Change
            </v-btn>
            <v-btn
              v-else-if="i > 0 && header.id === get_plan.id"
              rounded
              color="success"
              outlined
              disabled>
              Current
            </v-btn>
            <v-btn
              v-else-if="i > 0 && header.id > get_plan.id"
              rounded
              color="success"
              @click="select_plan(header.id)"
              :loading="changing_plan === (header.id)"
              :disabled="changing_plan !== false || (i < 4 && is_external_company_owner)"
              >
              {{ is_external_company_owner ? 'Sign up' : 
                get_plan.id === 0 ? 'Start trial' : 'Upgrade' }}
            </v-btn>
            <v-btn
              v-else-if="i > 0"
              text
              small
              @click="select_plan(header.id)"
              :loading="changing_plan === (header.id)"
              :disabled="changing_plan !== false"
              >
              Choose
            </v-btn>
          </td>
        </tr>
        </tfoot>
    </template>
          </v-simple-table>
        </v-card-text>
      </v-card>

      <v-alert 
        v-for="conflict in conflicts" 
        :key="conflict"
        type="warning"
        class="mt-4 conflict-alert"
        elevation="2"
        border="left"
        dense
      >
        {{ clear_text[conflict] }}
      </v-alert>
    </v-container>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { SubscriptionPriceFilter } from '@/utils/filters'
import ToolTip from '@/components/ToolTip'

export default {
  components: {
    ToolTip
  },
  watch: {
    get_plan: {
      handler: function(val){
      if(val) this.amount_logistics_connects = val.amount_logistics_connects || 1
    },
    immediate: true
  }

  },
  computed: {
    ...mapState({
      scopes: state => state.app.config.plan_limits,
      user: state => state.user.user,
      plans: state => state.app.config.plans,
      company: state => state.warehouse.company,
    }),
    ...mapGetters({
      get_plan: 'warehouse/get_plan',
      get_plan_conflicts: 'app/get_plan_conflicts',
      is_external_company_owner: 'app/is_external_company_owner'
    }),
    logistics_price(){
      return this.plans?.find(p => p.id === 5)?.price + (this.plans?.find(p => p.id === 5)?.price_per_logistics_connect * this.amount_logistics_connects)
    },
    headers(){
      return ([{
            text: '',
            align: 'left',
            sortable: false,
            value: 'name',
          }]).concat(this.plans
          .filter(p => !p.hide)
          .map(p => ({
            text: p.name, 
            align: 'center',
            sortable: false, 
            value: p.title,
            id: p.id
          })))
    }
  },
  
  
  filters: {
    SubscriptionPriceFilter
  },
  data() {
    return {
      changing_plan: false,
      amount_logistics_connects: 0,
      logo: require('@/assets/favicon-192.png'),
      clear_text: {
        amount_shelves: 'You have too many shelves in your warehouse. Delete shelves to be able to downgrade.',
        order_bucket_size: 'Your current settings allow more parcels per round than this plan. Change your setting before downgrading',
        amount_staff: 'You have more staff and/or invitations on your account than this plan allows. Remove staff accounts to be able to downgrade',
        store_transfer: 'Your store is currently transferred, which is not possible on this plan. Reclaim your store before changing plan.',
        connected_stores: 'You currently have more stores connected to your account than this plan allows. Remove stores before downgrading.',
        carrier_accounts: 'You currently have more carrier accounts connected than this plan allows. Remove carrier accounts before downgrading.',
        allow_product_scanning: 'You currently have barcode scanning enabled. Disable this feature to downgrade to this plan.',
        allow_shelf_scanning: 'You currently have shelf scanning enabled. Disable this feature to downgrade plan.',
        allow_auto_carrier: 'One or more of your stores use Carrier App Rates. Inactivate all these to downgrade plan.',
        amount_logistics_connects: 'You currently have more stores connected to your account than this plan allows. Remove stores before downgrading.'
      },
      headersOLD: [
          {
            text: '',
            align: 'left',
            sortable: false,
            value: 'name',
          },
          {
            text: 'Small Business',
            align: 'center',
            sortable: false,
            value: 'small',
            id: 2,
          },
          {
            text: 'Pro',
            align: 'center',
            sortable: false,
            value: 'pro',
            id: 3,
          },
          {
            text: 'Enterprise',
            align: 'center',
            sortable: false,
            value: 'enterprise',
            id: 4,
          },
      ],
      conflicts: []
    }
  },
  methods: {
    async select_plan(plan_id){
      console.log(plan_id)
      if(this.is_external_company_owner){
        const { ok } = await this.$prompt({
          text: 'By continuing you acknowledge your plan will be invoiced starting from this date.',
          buttons: [
            {
              value: { ok: true },
              text: 'Agree'
            },
            {
              value: { ok: false },
              text: 'Cancel'
            },
          ]
        })
        if(!ok) return 
      }
      this.conflicts = []
      const plan_title = this.headers.find(i => i.id === plan_id).value
      const amount_logistics_connects = this.amount_logistics_connects
      const conflicts = this.get_plan_conflicts(plan_title, amount_logistics_connects)
      if(conflicts.length) return this.conflicts = conflicts
      this.changing_plan = plan_id
      const charge = await this.$store.dispatch('warehouse/change_company_plan', { plan_id, amount_logistics_connects })
      if(charge?.confirmation_url ) window.open(charge.confirmation_url, '_top')
      else this.changing_plan = false
    }
  },
  mounted(){
    console.log('this.get_plan', this.get_plan)
  }
}
</script>
<style lang="scss" scoped>
.plans-page {
  background-color: #f6f6f7;
  min-height: 100%;
}

.plans-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
  animation: fadeIn 0.8s ease-out;
  
  // &:hover {
  //   transform: translateY(-5px);
  //   box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
  // }
  
  .v-card__title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding-bottom: 16px;
  }
  
  .v-card__text {
    padding-top: 16px;
  }
}

.conflict-alert {
  animation: fadeIn 0.6s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 960px) {
  .plans-card {
    margin-bottom: 16px;
  }
}
</style>
